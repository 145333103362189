export default (
  '<div class="viewer-container" touch-action="none">'
    + '<div class="viewer-canvas"></div>'
    + '<div class="viewer-footer">'
      + '<div class="viewer-title"></div>'
      + '<div class="viewer-toolbar"></div>'
      + '<div class="viewer-navbar">'
        + '<ul class="viewer-list"></ul>'
      + '</div>'
    + '</div>'
    + '<div class="viewer-tooltip"></div>'
    + '<div role="button" class="viewer-button" data-viewer-action="mix"></div>'
    + '<div class="viewer-player"></div>'
  + '</div>'
);
