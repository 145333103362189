//角色列表
const ADAOTER = "adapter" //适配师22
const ADMIN = "admin" //管理员2
const DOCTOR = "doctor" //医生21
const RECEPTIONIST = "receptionist"//前台24
const UNKNOW = "unknow"//
const VERFIER = "verifier"//验光师23
const SUPERADMIN = "superadmin"//超级管理员
const PUBLICVERFIER = "publicverifier"//公共验光师39
const PUBLICADAOTER = "publicadapter" //公共适配师40



//工作人员列表
const ORDERlENSESFILL = "OrderLensesFill"  //发单人
const ORDERLENSESSEND = "OrderLensesSend"  //填单人
const TESTPICE = "TestPiece"  //验片人
const CHARGEPICE = "ChargePiece"  //收片人
const GUIDE = "guide"  //指导人
const CROSSMIRROR = "CrossMirror"  //交镜人
const ADAPTATION = "adaptation"  //适配师
const FITTING = "Fitting"  //验配师
const CHECKER = "Checker" //检查人





export default {
  ADAOTER, ADMIN, DOCTOR, RECEPTIONIST, UNKNOW, VERFIER, SUPERADMIN, PUBLICVERFIER, PUBLICADAOTER,
  ORDERlENSESFILL, ORDERLENSESSEND, TESTPICE, CHARGEPICE, GUIDE, CROSSMIRROR, ADAPTATION, FITTING, CHECKER
}